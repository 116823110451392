.photo-album-cards .photo-album-card {
  /* Style for both desktop and mobile view */
}

@media (min-width: 768px) {
  /* Style for desktop view */
  .photo-album-cards .photo-album-card {
    /* Style for desktop view */
  }
}

@media (max-width: 767px) {
  /* Style for mobile view */
  .photo-album-cards .photo-album-card {
    flex-direction: column;
  }
}




.lightbox {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  
    background: rgba(0, 0, 0, 0.5);
  
    display: flex;
    align-items: center;
    align-items: center;
  
    visibility: hidden;
    opacity: 0;
  
    transition: opacity ease 0.4s;
  }
  
  .show {
    visibility: visible;
    opacity: 1;
  }
  
  .show_image {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
  }
  

  .imageDemoBlock img {
    max-width:200px;
}

.fullscreenmode {
    position:absolute;
    left:0;
    top:0;
    z-index:1000;
    width:100%;
    height:100%;   
    line-height:100%;
    background:rgba(0,0,0,0.5);    

        /* Add the following styles */
        display: flex;
        align-items: center;
        justify-content: center;



}

        /* Add styles for the left and right arrow elements */
        .arrow {
          position: absolute;
          top: 50%;
          font-size: 2rem;
          color: white;
          cursor: pointer;
        }
        .left-arrow {
          left: 1rem;
        }
        .right-arrow {
          right: 1rem;
        }

.fullscreenmodeimg {
    max-width:100%;
    margin:0 auto;
    display:block;
}


@media only screen and (max-width: 600px) {
  .fullscreenmode {
    position:inherit;
    left:0;
    top:0;
    z-index:1000;
    width:100%;
    height:100%;   
    line-height:100%;
    background:rgba(0,0,0,0.5);    

        /* Add the following styles */
        display: flex;
        align-items: center;
        justify-content: center;
}
}