nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background-color: white;
    color: white;
  }
  
  @media (max-width: 600px) {
    nav {
      flex-direction: column;
      padding: 2px 4px;
      margin: 2px 4px;

      height: auto;
    }
  }
  
  button {
    border: none;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
  }
  
  @media (max-width: 600px) {
    button {
      width: 100%;
      font-size: 12px;
      padding: 2px 4px;
      margin: 2px 4px;

    }
  }